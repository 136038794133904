const advantagesTabs = () => {
  const businessTabButton = document.getElementById('business-tab-button');
  const banksTabButton = document.getElementById('banks-tab-button');
  const businessTab = document.getElementById('business-tab');
  const banksTab = document.getElementById('banks-tab');
  if (businessTabButton && banksTabButton && businessTab && banksTab) {
    businessTabButton.addEventListener('click', () => {
      businessTabButton.classList.add('advantages__tab-button_active');
      banksTabButton.classList.remove('advantages__tab-button_active');
      businessTab.classList.add('advantages__tab_active');
      banksTab.classList.remove('advantages__tab_active');
    });
    banksTabButton.addEventListener('click', () => {
      businessTabButton.classList.remove('advantages__tab-button_active');
      banksTabButton.classList.add('advantages__tab-button_active');
      businessTab.classList.remove('advantages__tab_active');
      banksTab.classList.add('advantages__tab_active');
    });
  }
};

export default advantagesTabs;
