import menuMobile from './menu-mobile/menu-mobile';
import mainPageTitleAnimation from './top/top';
import advantagesTabs from './advantages/advantages';
// import mediaCarousel from "./media/media";
import carousel from './carousel/carousel';
import companySlider from './company/company';
import trainAnimation from './train/train';
import loginForm from './login-form/login-form';
import informationTable from './information/information';
import cfaHeader from './cfa/cfa-header/cfa-header';
import factoringHeader from './factoring/factoring-header';
import benefitsAccordion from './credits/benefits-accordion/benefits-accordion';
import verticalAccordion from './credits/vertical-accordion/vertical-accordion';
import rateChangeAnimation from './credits/credits-rate-cut/credits-rate-cut';
import ratesCompetition from './credits/credits-competition/credits-competition';
import handleDisclaimer from './disclaimer/disclaimer';

window.addEventListener('DOMContentLoaded', () => {
  handleDisclaimer();
  menuMobile();
  mainPageTitleAnimation();
  advantagesTabs();
  // mediaCarousel();
  carousel();
  companySlider();
  loginForm();
  trainAnimation();
  informationTable();
  cfaHeader();
  factoringHeader();
  benefitsAccordion();
  verticalAccordion();
  rateChangeAnimation();
  ratesCompetition();
});
