function cfaHeader() {
  $(document).ready(function () {
    const anchors = document.querySelectorAll('.cfa-header__anchor');
    if (anchors.length !== 0) {
      gsap.registerPlugin(ScrollToPlugin);

      for (const anchor of anchors) {
        anchor.addEventListener('click', () => {
          gsap.to(window, {
            duration: 1.8,
            scrollTo: '#cfa-research',
            ease: Power1.easeInOut,
          });
        });
      }
    }
  });
}

export default cfaHeader;
