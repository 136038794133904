function benefitsAccordion() {
  $(document).ready(function () {
    const accordions = document.querySelectorAll('.benefits-accordion');
    if (accordions.length !== 0) {
      accordions.forEach((accordion) => {
        const benefits = accordion.querySelectorAll(
          '.benefits-accordion__benefit'
        );

        if (benefits.length !== 0) {
          let currentBenefitIndex = 0;

          function expandBenefit(benefit) {
            benefit.classList.add('benefits-accordion__benefit_active');
          }

          function collapseBenefit(benefit) {
            benefit.classList.remove('benefits-accordion__benefit_active');
          }

          benefits.forEach((benefit, index) => {
            benefit.addEventListener('click', () => {
              if (index !== currentBenefitIndex) {
                collapseBenefit(benefits[currentBenefitIndex]);
                expandBenefit(benefit);
                currentBenefitIndex = index;
              }
            });
          });
        }
      });
    }
  });
}

export default benefitsAccordion;
