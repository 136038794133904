function companySlider() {
  $(document).ready(function () {
    const messages = [
      'Компания разрабатывает и внедряет инновационные решения, направленные на достижение существенного экономического эффекта для крупного бизнеса при использовании банковских продуктов',
      `Лайтхаус один из лидеров в России по созданию регулируемой платформы для цифровых финансовых активов в соответствии с Федеральным законом<br>О цифровых финансовых активах`,
      'Среди клиентов компании такие крупные компании как АО «Трансмашхолдинг» (крупнейший в России производитель ЖД транспорта), АО «Метровагонмаш» (поставщик для метрополитенов 11 стран мира)',
    ];
    let currentMessage = 0;
    const sliderMessage = document.getElementById('company-slider-message');
    const sliderButtonLeft = document.getElementById(
      'company-slider-button-left'
    );
    const sliderButtonRight = document.getElementById(
      'company-slider-button-right'
    );
    const sliderCounter = document.getElementById('company-slider-counter');

    if (
      sliderMessage &&
      sliderButtonLeft &&
      sliderButtonRight &&
      sliderCounter &&
      messages?.length
    ) {
      function updateSlider() {
        sliderCounter.textContent = `${currentMessage + 1} / ${
          messages.length
        }`;
        gsap
          .timeline()
          .to(sliderMessage, { opacity: 0, duration: 0.4 })
          .to(sliderMessage, { text: messages[currentMessage], duration: 0 })
          .to(sliderMessage, { opacity: 1, duration: 0.4 });
      }

      updateSlider();

      sliderButtonLeft.addEventListener('click', () => {
        currentMessage =
          currentMessage === 0 ? messages.length - 1 : currentMessage - 1;
        updateSlider();
      });

      sliderButtonRight.addEventListener('click', () => {
        currentMessage =
          currentMessage === messages.length - 1 ? 0 : currentMessage + 1;
        updateSlider();
      });
    }
  });
}

export default companySlider;
