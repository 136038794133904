const menuMobild = () => {
  $(document).ready(function () {
    const hamburger = document.querySelector('.hamburger');
    const close = document.querySelector('.menu-mobile__close');
    const menuMobile = document.querySelector('.menu-mobile');

    if (hamburger && close && menuMobile) {
      hamburger.addEventListener('click', function () {
        document.querySelector('body').classList.add('overflow-heidden');
        menuMobile.classList.add('menu-mobile--show');
      });

      close.addEventListener('click', function () {
        document.querySelector('body').classList.remove('overflow-heidden');
        menuMobile.classList.remove('menu-mobile--show');
      });

      window.addEventListener('resize', function () {
        document.querySelector('body').classList.remove('overflow-heidden');
        menuMobile.classList.remove('menu-mobile--show');
      });
    }
  });
};

export default menuMobild;
