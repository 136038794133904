function verticalAccordion() {
  $(document).ready(function () {
    const accordions = document.querySelectorAll('.vertical-accordion');

    if (accordions.length !== 0) {
      accordions.forEach((accordion) => {
        const benefits = [
          ...accordion.querySelectorAll('.vertical-accordion__benefit'),
        ];
        const titles = [
          ...accordion.querySelectorAll('.vertical-accordion__title-container'),
        ];
        const titleToggles = benefits.map(createAnimation);
        let currentBenefitIndex = -1;

        titles.forEach((title, index) => {
          title.addEventListener('click', () => toggleItem(title, index));
        });

        toggleItem(titles[0], 0);

        function toggleItem(clickedTitle, index) {
          if (index !== currentBenefitIndex) {
            titleToggles.forEach((toggleFn) => toggleFn(clickedTitle));
            currentBenefitIndex = index;
          }
        }

        function createAnimation(element) {
          let title = element.querySelector(
            '.vertical-accordion__title-container'
          );
          let benefit = element.querySelector(
            '.vertical-accordion__benefit-content'
          );

          gsap.set(benefit, { height: 'auto' });

          let animation = gsap
            .from(benefit, {
              height: 0,
              duration: 0.5,
              ease: 'power1.inOut',
            })
            .reverse();

          return function (clickedTitle) {
            if (clickedTitle === title) {
              animation.reversed(!animation.reversed());
            } else {
              animation.reverse();
            }
          };
        }
      });
    }
  });
}

export default verticalAccordion;
