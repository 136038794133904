function carousel() {
  const buttonLeft = document.getElementById('carousel__button-left');
  const buttonRight = document.getElementById('carousel__button-right');
  const carouselContent = document.getElementById('carousel__content');
  const carouselItems = document.querySelectorAll('.carousel__item');
  let currentPage = 0;
  let cursor = 0;
  let buttonsDisabled = false;
  const transitionTime = 900;

  const disableButtons = () => {
    buttonsDisabled = true;
    setTimeout(() => {
      buttonsDisabled = false;
    }, transitionTime);
  };

  if (
    buttonLeft &&
    buttonRight &&
    carouselContent &&
    carouselItems.length !== 0
  ) {
    if (carouselItems.length <= 3) {
      buttonLeft.style.display = 'none';
      buttonRight.style.display = 'none';
    }

    window.addEventListener('resize', () => {
      pages[currentPage].classList.remove('carousel__pager-item_active');
      currentPage = 0;
      cursor = 0;
      carouselContent.style.transform = `translateX(0px)`;
      pages[currentPage].classList.add('carousel__pager-item_active');
      buttonLeft.classList.add('carousel__button_disabled');
      buttonRight.classList.remove('carousel__button_disabled');
    });

    const pagerItems = Math.ceil(carouselItems.length / 3);
    const pager = document.querySelector('.carousel__pager');
    const pages = [];

    if (pagerItems > 1) {
      for (let i = 0; i < pagerItems; ++i) {
        const pagerItem = document.createElement('div');
        pagerItem.classList.add('carousel__pager-item');
        pages.push(pagerItem);
        pager.appendChild(pagerItem);
      }

      pages[currentPage].classList.add('carousel__pager-item_active');
    }

    buttonLeft.addEventListener('click', () => {
      if (cursor !== 0 && !buttonsDisabled) {
        cursor -= 3;
        if (cursor < 0) cursor = 0;
        pages[currentPage].classList.remove('carousel__pager-item_active');
        pages[currentPage - 1].classList.add('carousel__pager-item_active');
        currentPage -= 1;
        const width = carouselItems[0].getBoundingClientRect().width;
        carouselContent.style.transform = `translateX(-${cursor * width}px)`;
        disableButtons();
        setTimeout(() => {
          buttonRight.classList.remove('carousel__button_disabled');
          if (cursor === 0) {
            buttonLeft.classList.add('carousel__button_disabled');
          }
        }, transitionTime);
      }
    });

    buttonRight.addEventListener('click', () => {
      if (cursor < carouselItems.length - 3 && !buttonsDisabled) {
        cursor += 3;
        if (cursor >= carouselItems.length - 3) {
          cursor = carouselItems.length - 3;
        }
        pages[currentPage].classList.remove('carousel__pager-item_active');
        pages[currentPage + 1].classList.add('carousel__pager-item_active');
        currentPage += 1;
        const width = carouselItems[0].getBoundingClientRect().width;
        carouselContent.style.transform = `translateX(-${cursor * width}px)`;
        disableButtons();
        setTimeout(() => {
          buttonLeft.classList.remove('carousel__button_disabled');
          if (cursor === carouselItems.length - 3) {
            buttonRight.classList.add('carousel__button_disabled');
          }
        }, transitionTime);
      }
    });
  }
}

export default carousel;
