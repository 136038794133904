function loginForm() {
  $(document).ready(function () {
    const form = document.getElementById('application-form');

    if (form) {
      function ucFirst(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
      }

      function isEmailValid(email) {
        return email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      }

      function isValidINN(inn) {
        return inn && inn.length === 10;
      }

      const showError = (fieldName, errorText) => {
        const input = form[fieldName];
        input.parentElement.classList.add('input--error');
        const errorMessage = document.getElementById(`${fieldName}_error`);
        if (errorMessage) {
          errorMessage.style.display = 'block';
          errorMessage.textContent = errorText;
        }
      };

      const hideError = (fieldName) => {
        const input = form[fieldName];
        input.parentElement.classList.remove('input--error');
        const errorMessage = document.getElementById(`${fieldName}_error`);
        if (errorMessage) {
          errorMessage.style.display = 'none';
          errorMessage.textContent = '';
        }
      };

      const checkEmpty = (fieldName) => {
        const input = form[fieldName];
        if (input.value.trim().length === 0) {
          showError(fieldName, 'Поле не может быть пустым');
          return false;
        }
        return true;
      };

      const onChangeValue = (fieldName) => {
        hideError(fieldName);
      };

      const fields = [
        'nameCompany',
        'inn',
        'familyName',
        'phoneNumber',
        'email',
      ];

      fields.forEach((field) => {
        form[field].addEventListener('input', () => onChangeValue(field));
        form[field].addEventListener('blur', () => checkEmpty(field));
      });

      form.email.addEventListener('blur', () => {
        if (!isEmailValid(form.email.value)) {
          showError('email', 'Введите корректный email');
        }
      });

      form.familyName.addEventListener('input', () => {
        const value = form.familyName.value;
        if (value) {
          let everyWordUpperCase = value
            .split(' ')
            .map((word) => word.trim())
            .filter(Boolean)
            .map((word) => ucFirst(word))
            .join(' ');

          if (value.endsWith(' ')) {
            everyWordUpperCase += ' ';
          }

          form.familyName.value = everyWordUpperCase;
        }
      });

      form.phoneNumber.addEventListener('input', () => {
        const value = form.phoneNumber.value;
        if (value) {
          form.phoneNumber.value = value.slice(0, 11);
        }
      });

      form.inn.addEventListener('input', () => {
        const value = form.inn.value;
        if (value) {
          form.inn.value = value.slice(0, 10);
        }
      });

      form.inn.addEventListener('blur', () => {
        if (!isValidINN(form.inn.value)) {
          showError('inn', 'Количество символов не может быть менее 10');
        }
      });

      form.addEventListener('submit', (event) => {
        fields.forEach((field) => {
          if (!checkEmpty(field)) {
            event.preventDefault();
            return;
          }
        });

        if (!isEmailValid(form.email.value)) {
          showError('email', 'Введите корректный email');
          event.preventDefault();
          return;
        }

        if (!isValidINN(form.inn.value)) {
          showError('inn', 'Количество символов не может быть менее 10');
          event.preventDefault();
          return;
        }
      });
    }
  });
}

export default loginForm;
