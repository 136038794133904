function informationTable() {
  $(document).ready(function () {
    const table = document.getElementById('information-table');

    if (table) {
      const rowsData = [
        {
          solution: '000133493029',
          issuer: 'ООО “Транс-Холд Инжиниринг”',
          date: new Date(2022, 0, 2),
          file: '#',
        },
        {
          solution: '000133493030',
          issuer: 'ООО "МВМ"',
          date: new Date(2022, 1, 2),
          file: '#',
        },
        {
          solution: '000133493031',
          issuer: 'ООО "ТМХ"',
          date: new Date(2022, 2, 2),
          file: '#',
        },
      ];

      const solutionColumn = document.getElementById('solution-sort');
      const issuerColumn = document.getElementById('issuer-sort');
      const dateColumn = document.getElementById('date-sort');

      solutionColumn.addEventListener('click', () => onSortClick('solution'));
      issuerColumn.addEventListener('click', () => onSortClick('issuer'));
      dateColumn.addEventListener('click', () => onSortClick('date'));

      const sortData = {
        solution: {
          column: solutionColumn,
          direction: 'default',
          sortFunc: (lhs, rhs, direction) =>
            direction === 'ASC'
              ? lhs.localeCompare(rhs)
              : rhs.localeCompare(lhs),
        },
        issuer: {
          column: issuerColumn,
          direction: 'default',
          sortFunc: (lhs, rhs, direction) =>
            direction === 'ASC'
              ? lhs.localeCompare(rhs)
              : rhs.localeCompare(lhs),
        },
        date: {
          column: dateColumn,
          direction: 'default',
          sortFunc: (lhs, rhs, direction) => {
            console.log(rhs.getTime());
            return direction === 'ASC' ? lhs - rhs : rhs - lhs;
          },
        },
      };

      let currentSortColumn = null;

      function onSortClick(columnName) {
        if (currentSortColumn && currentSortColumn !== columnName) {
          sortData[currentSortColumn].column.querySelector(
            '.table-sort-asc'
          ).style = '';
          sortData[currentSortColumn].column.querySelector(
            '.table-sort-desc'
          ).style = '';
          sortData[currentSortColumn].direction = 'default';
        }

        currentSortColumn = columnName;

        const ascArrow =
          sortData[currentSortColumn].column.querySelector('.table-sort-asc');
        const descArrow =
          sortData[currentSortColumn].column.querySelector('.table-sort-desc');

        if (sortData[currentSortColumn].direction === 'DESC') {
          sortData[currentSortColumn].direction = 'ASC';
          ascArrow.style = 'fill: #2555fe; stroke: #2555fe;';
          descArrow.style = '';
          onSort();
        } else {
          sortData[currentSortColumn].direction = 'DESC';
          ascArrow.style = '';
          descArrow.style = 'fill: #2555fe; stroke: #2555fe;';
          onSort();
        }
      }

      function onSort() {
        console.log(currentSortColumn);
        console.log(sortData[currentSortColumn].direction);
        rowsData.sort((lhs, rhs) =>
          sortData[currentSortColumn].sortFunc(
            lhs[currentSortColumn],
            rhs[currentSortColumn],
            sortData[currentSortColumn].direction
          )
        );
        updateTable();
      }

      function updateTable() {
        table.innerHTML = '';

        for (const rowData of rowsData) {
          const row = document.createElement('tr');
          row.classList.add('information__table-row');

          const solution = document.createElement('td');
          solution.classList.add('information__table-cell');
          const solutionText = document.createElement('p');
          solutionText.classList.add('information__table-row-text');
          solutionText.textContent = rowData.solution;
          solution.appendChild(solutionText);

          const issuer = document.createElement('td');
          issuer.classList.add('information__table-cell');
          const issuerText = document.createElement('p');
          issuerText.classList.add('information__table-row-text');
          issuerText.textContent = rowData.issuer;
          issuer.appendChild(issuerText);

          const date = document.createElement('td');
          date.classList.add('information__table-cell');
          const dateText = document.createElement('p');
          dateText.classList.add('information__table-row-text');
          dateText.textContent = rowData.date.toLocaleDateString('ru');
          date.appendChild(dateText);

          const issueDocument = document.createElement('td');
          issueDocument.classList.add('information__table-cell');

          const documentLink = document.createElement('a');
          documentLink.href = rowData.file;
          documentLink.setAttribute('target', '_blank');
          documentLink.classList.add('information__document-link');

          const linkImage = document.createElement('img');
          linkImage.src = 'images/information-document.jpg';
          linkImage.setAttribute('alt', 'Скачать');
          linkImage.classList.add('information__document-image');

          documentLink.appendChild(linkImage);

          const documentLinkText = document.createElement('span');
          documentLinkText.classList.add('information__document-text');
          documentLinkText.textContent = 'Скачать';

          documentLink.appendChild(documentLinkText);

          issueDocument.appendChild(documentLink);

          row.appendChild(solution);
          row.appendChild(issuer);
          row.appendChild(date);
          row.appendChild(issueDocument);

          table.appendChild(row);
        }
      }

      updateTable();
    }
  });
}

export default informationTable;
