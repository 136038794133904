function ratesCompetition() {
  $(document).ready(function () {
    const ratesContainer = document.getElementById(
      'competition-rates-container'
    );
    const bestRate = document.getElementById('competition-best-rate');
    const rateStar = document.getElementById('competition-rate-star');
    const rateBadge = document.getElementById('competitor-badge-container');
    const competitorContent = document.getElementById('competitor-content');

    const ratesContainer2 = document.getElementById(
      'competition-rates-container2'
    );
    const bestRate2 = document.getElementById('competition-best-rate2');
    const rateStar2 = document.getElementById('competition-rate-star2');
    const rateBadge2 = document.getElementById('competitor-badge-container2');
    const competitorContent2 = document.getElementById('competitor-content2');

    if (
      [
        ratesContainer,
        bestRate,
        rateStar,
        rateBadge,
        competitorContent,
        ratesContainer2,
        bestRate2,
        rateStar2,
        rateBadge2,
        competitorContent2,
      ].every(Boolean)
    ) {
      function animateForward() {
        gsap.to(ratesContainer, {
          y: '100%',
          duration: 3.7,
          delay: 0.8,
          ease: 'power3.inOut',
        });
        gsap
          .timeline()
          .to(bestRate, {
            y: '0%',
            delay: 2.9,
          })
          .to(rateStar, { opacity: 0 })
          .to(rateBadge, {
            opacity: 0,
            duration: 0.5,
          })
          .to(rateBadge, {
            height: 0,
            duration: 0.5,
            onComplete: () => {
              competitorContent.classList.remove(
                'credits-competition__competitor-content_active'
              );
            },
          });

        gsap.to(ratesContainer2, {
          y: '100%',
          duration: 3.5,
          delay: 0.8,
          ease: 'power3.inOut',
        });
        gsap
          .timeline()
          .to(bestRate2, {
            y: '0%',
            delay: 3.1,
          })
          .to(rateStar2, { opacity: 1 })
          .to(rateBadge2, {
            height: 'auto',
            duration: 0.5,
          })
          .to(rateBadge2, {
            opacity: 1,
            duration: 0.5,
            onComplete: () => {
              competitorContent2.classList.add(
                'credits-competition__competitor-content_active'
              );
              setTimeout(animateBackward, 2000);
            },
          });
      }

      function animateBackward() {
        gsap.to(bestRate, {
          y: '-100%',
          delay: 1,
          duration: 1,
        });
        gsap
          .timeline()
          .to(ratesContainer, {
            y: '0',
            duration: 3.7,
            ease: 'power3.inOut',
          })
          .to(rateStar, { opacity: 1 })
          .to(rateBadge, {
            height: 'auto',
            duration: 0.5,
            onComplete: () => {
              competitorContent.classList.add(
                'credits-competition__competitor-content_active'
              );
            },
          })
          .to(rateBadge, {
            opacity: 1,
            duration: 0.5,
          });

        gsap.to(bestRate2, {
          y: '-100%',
          delay: 0.8,
          duration: 0.6,
        });
        gsap
          .timeline()
          .to(ratesContainer2, {
            y: '0',
            duration: 3.5,
            ease: 'power3.inOut',
          })
          .to(rateStar2, { opacity: 0 })
          .to(rateBadge2, {
            opacity: 0,
            duration: 0.5,
            onComplete: function () {
              competitorContent2.classList.remove(
                'credits-competition__competitor-content_active'
              );
            },
          })
          .to(rateBadge2, {
            height: 0,
            duration: 0.5,
            onComplete: () => {
              setTimeout(animateForward, 2000);
            },
          });
      }

      animateForward();
    }
  });
}

export default ratesCompetition;
