function rateChangeAnimation() {
  $(document).ready(function () {
    const ratesContainer = document.getElementById('rates-container');
    const bestRate = document.getElementById('best-rate');
    const rateBadge = document.getElementById('rate-badge');
    const rateBadgeText = document.getElementById('rate-badge-text');

    if (ratesContainer && bestRate && rateBadge && rateBadgeText) {
      gsap.timeline().to(ratesContainer, {
        y: '100%',
        duration: 4,
        delay: 1,
        ease: 'power3.inOut',
      });
      gsap.timeline().to(bestRate, {
        y: '0%',
        delay: 3.85,
      });

      gsap
        .timeline()
        .to(rateBadge, {
          backgroundColor: '#73FFBC',
          delay: 5,
        })
        .to(
          rateBadgeText,
          {
            text: 'Лучшая ставка',
            delay: 5,
          },
          0
        );
    }
  });
}

export default rateChangeAnimation;
