const trainAnimation = () => {
  $(document).ready(function () {
    const train = document.querySelector('#train');
    if (train) {
      let bridgeWidth = document.querySelector('.train').offsetWidth;
      let lastStation = bridgeWidth + train.offsetWidth + 100;

      function trainAnimation() {
        let tl = gsap
          .timeline()
          .to(train, 0.3, {
            opacity: 1,
          })
          .to(train, 1.5, {
            yoyo: true,
            repeatRefresh: true,
            x: -bridgeWidth / 2,
          })
          .to(train, 3, {
            delay: 6,
            x: -lastStation,
            opacity: 0.7,
            ease: Quart.easeIn,
          })
          .to(train, 0.3, {
            opacity: 0,
          });

        return tl;
      }

      gsap
        .timeline({ repeat: -1, defaults: { ease: Power1.easeOut } })
        .add(trainAnimation(), 3)
        .repeatDelay(0.8);
    }
  });
};

export default trainAnimation;
