function handleDisclaimer() {
  $(document).ready(function () {
    const disclaimer = document.getElementById('disclaimer');
    const disclaimerButton = document.getElementById('disclaimer-button');
    if (disclaimer && disclaimerButton) {
      if (localStorage.getItem('disclaimer')) return;
      disclaimer.style.display = 'flex';
      disclaimerButton.addEventListener('click', () => {
        disclaimer.style.display = 'none';
        localStorage.setItem('disclaimer', '1');
      });
    }
  });
}

export default handleDisclaimer;
