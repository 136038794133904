const mainPageTitleAnimation = () => {
  $(document).ready(function () {
    const desktopPageTitle = document.getElementById('desktop-change-text');
    const mobilePageTitle = document.getElementById('mobile-change-text');

    if (desktopPageTitle && mobilePageTitle) {
      gsap.registerPlugin(TextPlugin);

      function textAnimationDesktop(node) {
        gsap
          .timeline({ repeat: -1 })
          .to(node, {
            duration: 0.75,
            text: 'биржевой факторинг',
            ease: 'none',
            repeat: 1,
            yoyo: true,
            repeatDelay: 2,
          })
          .to(node, {
            duration: 0.75,
            text: 'оборотные кредиты',
            ease: 'none',
            repeat: 1,
            yoyo: true,
            repeatDelay: 2,
          })
          .to(node, {
            duration: 1,
            text: 'цифровые финансовые активы',
            ease: 'none',
            repeat: 1,
            yoyo: true,
            repeatDelay: 2,
          });
      }

      function textAnimationMobile(node) {
        gsap
          .timeline({ repeat: -1 })
          .to(node, {
            duration: 0.75,
            text: 'биржевой факторинг',
            ease: 'none',
            repeat: 1,
            yoyo: true,
            repeatDelay: 2,
          })
          .to(node, {
            duration: 0.75,
            text: 'оборотные кредиты',
            ease: 'none',
            repeat: 1,
            yoyo: true,
            repeatDelay: 2,
          })
          .to(node, {
            duration: 0.75,
            text: 'цифровые активы',
            ease: 'none',
            repeat: 1,
            yoyo: true,
            repeatDelay: 2,
          });
      }

      textAnimationDesktop(desktopPageTitle);
      textAnimationMobile(mobilePageTitle);
    }
  });
};

export default mainPageTitleAnimation;
